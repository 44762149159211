<template>
  <!--
    class="mb-0"
   -->
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'px-8' : 'px-4'">
    <!-- ==== DIALOG SUCCESS ==== -->
    <!-- <v-dialog v-model="dialog.success" persistent width="425">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5">
          <div class="text-center">
            <img src="@/assets/images/confirmation.png" width="250" />
            <p
              class="font-weight-bold mb-1 px-5"
              style="font-size: 1.1rem !important;"
            >
              Terimakasih, Anda telah berhasil melakukan {{ dialog.deleteState != '' ? 'penghapusan Transaksi' : 'pengembalian Dana' }} .
            </p>
            <p class="body-2 font-weight-regular">
              Silahkan klik tombol dibawah ini
            </p>
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              outlined
              color="#d31145"
              class="text-capitalize"
              @click="
                dialog.success = false;
                dialog.deleteState = '';
              "
            >
              Oke
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <!-- ===== DIALOG DETAIL TRANSAKSI ===== -->
    <v-dialog
      v-model="dialog.detailSalesResume"
      fullscreen
      scrollable
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark max-height="70" color="#d31145">
          <v-toolbar-title :class="$vuetify.breakpoint.name === 'xs' ? 'body-2' : ''"
            >Detail Penjualan
            <span class="font-weight-bold">({{ transaction_number }})</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark fab @click="dialog.detailSalesResume = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-row>
            <v-col cols="12" md="6" :class="$vuetify.breakpoint.name === 'xs' ? 'pt-1' : ''">
              <!-- ==== INFORMASI TRANSAKSI ==== -->
              <v-card flat>
                <div class="font-weight-regular text-second" :class="$vuetify.breakpoint.name === 'xs' ? 'body-1 mb-1' : 'text-h6 mb-5'">
                  Informasi Transaksi
                </div>
                <v-simple-table class="px-3">
                  <template>
                    <tbody>
                      <tr v-for="(item, key) in cardDetailInfo.data_array" :key="key">
                        <th class="text-second pl-0" :width="$vuetify.breakpoint.name === 'xs' ? '30%' : '30%'">{{ item.label }}</th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right text-second">
                          {{ item.value }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <!-- ==== RINGKASAN TOTAL ==== -->
              <v-card flat>
                <div class="font-weight-regular text-second" :class="$vuetify.breakpoint.name === 'xs' ? 'body-1 mb-1' : 'text-h6 mb-5'">
                  Ringkasan Total
                </div>
                <v-simple-table class="px-3">
                  <template>
                    <tbody>
                      <tr v-for="(item, key) in cardDetailSummary.data_array" :key="key">
                        <th class="text-second pl-0" :width="$vuetify.breakpoint.name === 'xs' ? '30%' : '30%'">
                          {{ item.label }}
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                size="15"
                                class="ml-4"
                                color="#d31145"
                                v-on="on"
                                v-if="item.note.title !== ''"
                                v-bind="attrs">mdi-help-circle
                              </v-icon>
                            </template>
                            <div class="d-flex flex-column">
                              <span class="font-weight-bold">{{ item.note.title }}</span>
                              <span>{{ item.note.content }}</span>
                            </div>
                          </v-tooltip>
                        </th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right text-second">
                          {{ item.value }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>

          <div class="font-weight-regular mt-7 text-second" :class="$vuetify.breakpoint.name === 'xs' ? 'body-1 mb-0' : 'text-h6 mb-5'">
            List Transaksi
          </div>
          <v-data-table
            flat
            class="laporan"
            :class="$vuetify.breakpoint.name === 'xs' ? 'mt-0' : 'mt-5'"
            :headers="headersDetail"
            :loading="loading"
            hide-default-footer
            disable-pagination
            :options.sync="options"
            :items="cardDetail.data_array"
          >
            <template v-slot:[`item.product_image_url`]="{ item }">
              <v-list-item class="pa-0">
                <v-list-item-avatar class="mr-3">
                  <v-img
                    :lazy-src="item.product_image_url ? item.product_image_url : 'https://cdn-icons-png.flaticon.com/512/4689/4689798.png'"
                    :src="item.product_image_url ? item.product_image_url : 'https://cdn-icons-png.flaticon.com/512/4689/4689798.png'">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height justify-center ma-0"
                        align="center"                    
                      >
                        <v-progress-circular indeterminate color="#d31145">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-list-item-avatar>
              </v-list-item>
            </template>
            <template v-slot:[`item.product_code`]="{ item }">
              {{ item.product_code }}
            </template>
            <template v-slot:[`item.product_name`]="{ item }">
              {{ item.product_name }}
            </template>
            <template v-slot:[`item.category_name`]="{ item }">
              {{ item.category_name }}
            </template>
            <template v-slot:[`item.product_qty`]="{ item }">
              {{ item.product_qty | num_format }}
              {{ item.product_unit }}
            </template>
            <template v-slot:[`item.product_selling_price`]="{ item }">
              {{ item.product_selling_price }}
            </template>
            <template v-slot:[`item.product_purchase_price`]="{ item }">
              {{ item.product_purchase_price }}
            </template>
            <template v-slot:[`item.product_discount_nominal`]="{ item }">
              {{ item.product_discount_nominal }}
            </template>
            <template v-slot:[`item.product_subtotal_price`]="{ item }">
              {{ item.product_subtotal_price }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ==== DATA SALES SUMMARY ==== -->
    <div class="my-5">
      <div class="text--secondary mb-3" :class="$vuetify.breakpoint.name === 'xs' ? 'body-1 font-weight-bold' : 'title'">
        Data Ringkasan Penjualan Refund
        <!-- <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="initialize()" v-bind="attrs" v-on="on" icon>
              <v-icon color="green">mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh Data</span>
        </v-tooltip> -->
      </div>
      <div class="mb-3 d-flex align-center flex-wrap">
        <Export
          class=" mr-2"
          :disabled="$store.state.loading"
          :file_type="'xls'"
          :btnText="'Export Excel'"
          :btnIcon="'mdi-microsoft-excel'"
          :btnColor="'teal'"
          :url="`franchise/report/penjualan_refund/export_excel?store_id=${selected_store_id}`"
          :title="'Data Ringkasan Penjualan'"
          ref="base-export-excel"
          v-on:onClickExport="responseExport"
        />
        <Export
          class=" mr-2"
          :disabled="$store.state.loading"
          :file_type="'pdf'"
          :btnText="'Export PDF'"
          :btnIcon="'mdi-file-pdf-box'"
          :btnColor="'#f40f02'"
          :url="`franchise/report/penjualan_refund/export_pdf?store_id=${selected_store_id}`"
          :title="'Data Ringkasan Penjualan'"
          ref="base-export-pdf"
          v-on:onClickExport="responseExport"
        />
        <v-btn
          depressed
          :fab="$vuetify.breakpoint.name !== 'xs' ? false : true"
          :x-small="$vuetify.breakpoint.name !== 'xs' ? false : true"
          :width="$vuetify.breakpoint.name === 'xs' ? '' : '150'"
          elevation="0"
          :disabled="process.run"
          color="#aaa9ad"
          class="white--text text-capitalize mr-2"
          @click="$refs['base-table'].isFilterVisible = true"
        >
          <span v-if="$vuetify.breakpoint.name !== 'xs'">Filter</span>
          <v-icon v-if="$vuetify.breakpoint.name === 'xs'">mdi-filter-variant</v-icon>
        </v-btn>

        <!-- <v-btn
          depressed
          v-show="sales_id.length > 0"
          :fab="$vuetify.breakpoint.name !== 'xs' ? false : true"
          :x-small="$vuetify.breakpoint.name !== 'xs' ? false : true"
          :width="$vuetify.breakpoint.name === 'xs' ? '' : '150'"
          elevation="0"
          :disabled="process.run"
          color="#d31145"
          class="white--text text-capitalize"
          @click="
            dialog.deleteState = 'bulk'
            dialog.delete = true;">
          <span v-if="$vuetify.breakpoint.name !== 'xs'">Delete Transaksi</span>
          <v-icon v-if="$vuetify.breakpoint.name === 'xs'">mdi-delete</v-icon>
        </v-btn> -->
      </div>

      <!-- ==== FILTER STATUS ==== -->
      <div
        v-if="
          status.date ||
            status.store ||
            status.cashier ||
            status.sales ||
            status.payment
        "
      >
        Pencarian :
        <v-chip
          v-if="status.date"
          class="ma-2"
          :small="$vuetify.breakpoint.name === 'xs' ? true : false"
          close
          @click:close="handleCloseChips('date')"
        >
          Tanggal: {{ filterdate }}
        </v-chip>
        <v-chip
          v-if="status.sales"
          class="ma-2"
          close
          :small="$vuetify.breakpoint.name === 'xs' ? true : false"
          @click:close="handleCloseChips('sales')"
          style="text-transform:capitalize;"
        >
          Penjualan: {{ filternamesales }}
        </v-chip>
        <v-chip
          v-if="status.payment"
          class="ma-2"
          close
          :small="$vuetify.breakpoint.name === 'xs' ? true : false"
          @click:close="handleCloseChips('payment')"
        >
          Pembayaran: {{ filternamepayment }}
        </v-chip>
      </div>

      <!-- ==== DATA SUMMARY ==== -->
      <v-expansion-panels flat accordion v-model="panel" focusable>
        <v-expansion-panel expand v-model="panel">
          <v-expansion-panel-header
            class="title text--secondary"
            style="font-size:1rem !important;">
            Rincian Transaksi
          </v-expansion-panel-header>
          <v-expansion-panel-content
            class="mb-2"
            :class="$vuetify.breakpoint.name === 'xs' ? '' : 'pt-5 '"
            style=" box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; border-radius:5px;">
            <v-row>
              <v-col
                cols="12" 
                md="3"
                sm="4" 
                :class="$vuetify.breakpoint.name === 'xs' ? 'px-1' : ''"
                v-for="(item, key) in total" :key="key">
                <v-card class="box-shadow border-radius pa-2">
                  <v-card-actions class="pt-2 px-0">
                    <div>
                      <v-card-title
                        class="font-weight-bold text--secondary px-0 py-0 pb-1"
                        :style="$vuetify.breakpoint.name === 'xs' ? 'font-size: 11px; line-height: 1; height: 30px' : ''"
                        :class="$vuetify.breakpoint.name === 'xs' ? '' : 'body-2'"
                      >
                        {{ item.label }}
                      </v-card-title>
                      <v-card-text :class="$vuetify.breakpoint.name === 'xs' ? 'title text-second px-0' : 'headline text-second px-0'">
                        {{ item.value }}
                      </v-card-text>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- ==== LIST DATA SALES SUMMARY ==== -->
      <div v-show="process.run && list.length < 1">
        <v-skeleton-loader
          :loading="process.run"
          transition="scale-transition"
          :tile="false"
          type="table-tbody"
        >
        </v-skeleton-loader>
      </div>

      <!-- customHeight="calc(100vh - 200px)" -->
      <base-table
        :customHeight="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'calc(100vh - 200px)' : 'calc(100vh - 450px)'"
        v-show="displayBaseTable"
        v-on:onRetrieveDataEnd="responseDataEnd"
        v-on:onRetrieveDataStart="responseDataStart"
        v-on:onResetFilter="resetFilter"
        v-on:onGetDataModel="getDataModel"
        :isExternalFilterButton="true"
        :isFilterOnHeader="true"
        ref="base-table"
        dateType="date"
        dateFormat="DD MMMM YYYY"
        :filterHeader="filterHeader_salesResume"
        itemKey="id"
        :single-select="singleSelect"
        :show-select="false"
        :headers="headers"
        :retrieveDataURL="`franchise/report/penjualan_refund?store_id=${selected_store_id}`"
        filterType="laporan">
        <template v-slot:[`item.aksi_table`]="{ item }">
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                @click="toRefund(item)"
                small
                text
                min-width="30"
                class="text-capitalize red--text text--darken-2 pa-0 mr-1 c-p"
              >
                <v-icon small>mdi-cash-refund</v-icon>
              </v-btn>
            </template>
            <span>Refund Transaksi</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                @click="toSoftDelete(item)"
                small
                text
                min-width="30"
                class="text-capitalize red--text text--darken-2 pa-0 mr-1 c-p"
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Delete Transaksi</span>
          </v-tooltip> -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="toDetail(item)"
                v-on="on"
                v-bind="attrs"
                small
                text
                min-width="30"
                class="text-capitalize orange--text text--darken-1 pa-0 mr-1 c-p"
              >
                <v-icon small>mdi-information-outline</v-icon>
              </v-btn>
            </template>
            <span>Detail Transaksi</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.input_complete_datetime`]="{ item }">
          <span v-if="item.input_complete_datetime !== ''">
            {{ item.input_complete_datetime | datetime }}
          </span>
          <span v-else>
            -
          </span>
        </template>
        <template v-slot:[`item.sales_order_number`]="{ item }">
          {{ item.sales_order_number }}
        </template>
        <template v-slot:[`item.sales_order_transaction_number`]="{ item }">
          {{ item.sales_order_transaction_number }}
        </template>
        <template v-slot:[`item.sales_order_payment_method`]="{ item }">
          {{ item.sales_order_payment_method }}
        </template>
        <template v-slot:[`item.sales_order_type_title`]="{ item }">
          {{ item.sales_order_type_title }}
        </template>
        <template v-slot:[`item.sales_order_total_product_qty`]="{ item }">
          {{ item.sales_order_total_product_qty }}
        </template>
        <template v-slot:[`item.sales_order_total_product_price`]="{ item }">
          {{ item.sales_order_total_product_price | num_format }}
        </template>
        <template v-slot:[`item.sales_order_total_product_purchase_price`]="{ item }">
          {{ item.sales_order_total_product_purchase_price | num_format }}
        </template>
        <template v-slot:[`item.sales_order_total_bruto`]="{ item }">
          {{ item.sales_order_total_bruto | num_format }}
        </template>
        <template v-slot:[`item.sales_order_total_diskon_nominal`]="{ item }">
          {{ item.sales_order_total_diskon_nominal | num_format }}
        </template>
        <!-- <template v-slot:[`item.sales_order_total_nett_price`]="{ item }">
          {{ item.sales_order_total_nett_price | num_format }}
        </template> -->
        <template v-slot:[`item.total_price_after_discount`]="{ item }">
          {{ item.total_price_after_discount | price }}
        </template>
        <template v-slot:[`item.sales_order_total_charge_nominal`]="{ item }">
          {{ item.sales_order_total_charge_nominal | num_format }}
        </template>
        <template v-slot:[`item.sales_order_total_tax_nominal`]="{ item }">
          {{ item.sales_order_total_tax_nominal | num_format }}
        </template>
        <template v-slot:[`item.sales_order_other_cost`]="{ item }">
          {{ item.sales_order_other_cost | num_format }}
        </template>
        <template v-slot:[`item.sales_order_grand_total`]="{ item }">
          {{ item.sales_order_grand_total | num_format }}
        </template>
        <template v-slot:[`item.sales_order_total_netto_bersih`]="{ item }">
          {{ item.sales_order_total_netto_bersih | num_format }}
        </template>

        <template v-slot:filterTitle>
          Filter Ringkasan Penjualan Refund
        </template>
      </base-table>

      <!-- ==== COMPONENT DATA KOSONG ==== -->
      <Empty v-show="emptyData" />
    </div>
  </div>
</template>
<script>
import { get, post, put, destroy, exportExcel } from "@/service/Axios";
import Pagination from "@/components/Pagination";
// import DatetimePicker from "@/components/DatetimePicker";
import Empty from "@/components/Empty";
import moment from "moment";
import Export from "@/components/Export";

export default {
  data() {
    return {
      panel: "",
      /**
       * DATA COMPONENT BASETABLE
       */
      displayBaseTable: false,
      emptyData: false,
      singleSelect: false,
      showeSelect: true,
      dataPagination: {
        current: 0,
        limit: 0,
        total_data: 0,
        page: 1,
        itemsPerPage: 10,
      },
      headers: [
        { text: "Aksi", align: "center", width: 50, sortable: false, value: "aksi_table" },
        {
          text: "Tanggal",
          align: "center",
          value: "input_complete_datetime",
          width: 200,
          sortable: true,
        },
        {
          text: "Nomor Pembelian",
          align: "center",
          value: "number",
          sortable: true,
          width: 200,
        },
        {
          text: "Nomor Transaksi",
          align: "left",
          value: "transaction_number",
          sortable: true,
          width: 200,
        },
        {
          text: "Metode Pembayaran",
          align: "left",
          value: "payment_method",
          sortable: true,
          width: 170,
        },
        {
          text: "Tipe Penjualan",
          align: "left",
          value: "type_title",
          sortable: true,
          width: 140,
        },
        {
          text: "Total Item",
          align: "center",
          value: "total_product_item",
          sortable: true,
          width: 120,
        },
        {
          text: "Total Harga Beli (Rp)",
          align: "end",
          value: "total_product_purchase_price",
          sortable: true,
          width: 200,
        },
        {
          text: "Total Harga Jual (Rp)",
          align: "end",
          value: "total_product_price",
          sortable: true,
          width: 200,
        },
        {
          text: "Total Bruto (Rp)",
          align: "end",
          value: "total_bruto_price",
          sortable: true,
          width: 200,
        },
        {
          text: "Total Diskon (Rp)",
          align: "end",
          value: "total_discount_nominal",
          sortable: true,
          width: 200,
        },
        {
          text: "Total Harga Sesudah Diskon (Rp)",
          align: "end",
          value: "total_price_after_discount",
          sortable: true,
          width: 200,
        },
        {
          text: "Total Biaya Servis (Rp)",
          align: "end",
          value: "total_charge_nominal",
          sortable: true,
          width: 200,
        },
        {
          text: "Total PPN (Rp)",
          align: "end",
          value: "total_tax_nominal",
          sortable: true,
          width: 200,
        },
        {
          text: "Total Biaya Tambahan (Rp)",
          align: "end",
          value: "other_cost",
          sortable: true,
          width: 200,
        },
        {
          text: "Total Yang Harus di Bayar (Rp)",
          align: "end",
          value: "grand_total",
          sortable: true,
          width: 220,
        },
        {
          text: "Total Netto (Rp)",
          align: "end",
          value: "total_nett_price",
          sortable: true,
          width: 200,
        },
      ],
      /**
       * END DATA COMPONENT BASETABLE
       */

      hari: new Date().toISOString().substr(0, 10),
      cardDetail: {},
      cardDetailInfo: {},
      cardDetailSummary: {},
      options: {},
      loading: false,
      showPin: false,
      totalData: 8,
      pagination: {
        data: {},
        page: "",
      },
      limit: 10,
      page: 1,
      transaction_number: 0,
      hide: "d-flex",
      date: [],
      sales_id: [],
      selected: {
        pagination: 0,
      },
      form: {
        sales_id: 0,
        access_pin: "",
        refund_note: "",
      },
      formError: {
        sales_id: "",
        access_pin: "",
        refund_note: "",
      },
      response: {
        message: "",
      },
      dialog: {
        expand: false,
        filtered: false,
        detailSalesResume: false,
        access_pin: false,
        success: false,
        delete: false,
        deleteState: ''
      },
      process: {
        formRecount: false,
        formUpdate: false,
        formExport: false,
        disableRecount: false,
        disableUpdate: false,
        disableOut: false,
        run: false,
        loading: false,
      },
      filterSave: {
        date: [],
        store: "",
        cashier: "",
        payment: "",
        sales: "",
      },
      filterstore: "",
      filternamestore: "",
      filtercashier: "",
      filternamecashier: "",
      filtersalestype: "",
      filternamesales: "",
      filterpaymenttype: "",
      filternamepayment: "",
      filterdate: "",
      status: {
        showTimeRangePanel: false,
        date: false,
        store: false,
        cashier: false,
        sales: false,
        payment: false,
      },
      headersDetail: [
        {
          text: "Gambar",
          align: "left",
          value: "product_image_url",
          width: 100,
        },
        {
          text: "Kode Produk",
          align: "center",
          value: "product_code",
          sortable: true,
          width: 100,
        },
        {
          text: "Nama Produk",
          align: "left",
          value: "product_name",
          sortable: true,
          width: 200,
        },
        {
          text: "Kategori Produk",
          align: "left",
          value: "category_name",
          sortable: true,
          width: 200,
        },
        {
          text: "Jumlah Kuantitas",
          align: "center",
          value: "product_qty",
          sortable: true,
          width: 100,
        },
        {
          text: "Harga Jual (Rp)",
          align: "end",
          value: "product_selling_price",
          sortable: true,
          width: 200,
        },
        {
          text: "Harga Beli (Rp)",
          align: "end",
          value: "product_purchase_price",
          sortable: true,
          width: 200,
        },
        {
          text: "Harga Markup (Rp)",
          align: "end",
          value: "product_markup_price",
          sortable: true,
          width: 200,
        },
        {
          text: "Total Harga Toping (Rp)",
          align: "end",
          value: "product_total_topping_price",
          sortable: true,
          width: 200,
        },
        {
          text: "Harga Diskon (Rp)",
          align: "end",
          value: "product_discount_nominal",
          sortable: true,
          width: 200,
        },
        {
          text: "Total Bruto (Rp)",
          align: "end",
          value: "product_bruto_price",
          sortable: true,
          width: 200,
        },
        {
          text: "Total Harga (Rp)",
          align: "end",
          value: "product_subtotal_price",
          sortable: true,
          width: 200,
        },
        {
          text: "Total Netto (Rp)",
          align: "end",
          value: "product_nett_price",
          sortable: true,
          width: 200,
        },
      ],

      paymentType: [
        { text: "Tunai" },
        { text: "EDC" },
        { text: "Transfer" },
        { text: "OVO" },
        { text: "GOPAY" },
        { text: "Lain-lain" },
      ],
      list: [],
      paymentMethod: [],
      sales: [],
      total: {},
      test: {},
    };
  },
  components: {
    Empty,
    Pagination,
    // DatetimePicker,
    Export,
  },
  computed: {
    filterHeader_salesResume() {
      return [
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        
        {
          type: "date_range",
          column: "input_complete_datetime",
          columnFilter: "input_complete_datetime",
          valueDefault: {},
          label: "Tanggal mulai - sampai",
        },

        {
          type: "opsi",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },

        {
          type: "select",
          column: "payment_method",
          valueDefault: "",
          items: this.paymentMethod,
          itemText: "name",
          itemValue: "name",
          label: "Berdasarkan Metode Pembayaran",
        },
        {
          type: "select",
          items: this.sales,
          column: "type_title",
          valueDefault: "",
          label: "Berdasarkan Tipe Penjualan",
          itemText: "title",
          itemValue: "title",
        },

        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
      ]
    },
    selected_store_id () {
      return JSON.parse(localStorage.getItem('selected_store_id'));
    },
  },

  created() {
    this.fetchSales();
    this.fetchPaymentMethod();
  },

  mounted() {
    // do {
    //   setTimeout(() => {}, 250);
    // } while (!this.$refs["base-table"]);
    // this.$refs["base-table"].disableRetrieveData = false;
  },
  methods: {
    

    /**
     * EVENTS
     */
    resetFilter() {
      this.$refs["base-table"].params.date1 &&
        delete this.$refs["base-table"].params.date1;
      this.$refs["base-table"].params.date2 &&
        delete this.$refs["base-table"].params.date2;
      this.$refs["base-table"].params.payment_method &&
        delete this.$refs["base-table"].params.payment_method;
      this.$refs["base-table"].params.sales_type &&
        delete this.$refs["base-table"].params.sales_type;
    },

    clearFilterChip(type) {
      switch (type) {
        case 'date':
          delete this.$refs["base-table"].params["filter[0][type]"];
          delete this.$refs["base-table"].params["filter[0][field]"];
          delete this.$refs["base-table"].params["filter[0][comparison]"];
          delete this.$refs["base-table"].params["filter[0][value]"];
          break;
        case 'payment':
          delete this.$refs["base-table"].params["filter[1][type]"];
          delete this.$refs["base-table"].params["filter[1][field]"];
          delete this.$refs["base-table"].params["filter[1][comparison]"];
          delete this.$refs["base-table"].params["filter[1][value]"];
          break;
        case 'sales':
          delete this.$refs["base-table"].params["filter[2][type]"];
          delete this.$refs["base-table"].params["filter[2][field]"];
          delete this.$refs["base-table"].params["filter[2][comparison]"];
          delete this.$refs["base-table"].params["filter[2][value]"];
          break;
        default:
          break;
      }

      this.$refs["base-table"].retrieveData();
      this.resetFilter();
    },

    handleCloseChips(mode) {
      switch (mode) {
        case "date":
          this.$refs[
            "base-table"
          ].filterModel.input_complete_datetime.value = {};
          this.status.date = false;
          this.filterdate = "";
          // this.$refs["base-table"].retrieveData();
          
          this.resetFilter();
          this.clearFilterChip(mode)
          break;
        case "sales":
          this.status.sales = false;
          this.filternamesales = "";
          this.filtersalestype = this.$refs[
            "base-table"
          ].filterModel.type_title.value = "";
          // this.$refs["base-table"].retrieveData();
          this.resetFilter();
          this.clearFilterChip(mode)
          break;
        case "payment":
          this.status.payment = false;
          this.filternamepayment = "";
          this.$refs["base-table"].filterModel.payment_method.value = "";
          // this.$refs["base-table"].retrieveData();
          this.resetFilter();
          this.clearFilterChip(mode)
          break;
      }
      if (this.status.date ||
            this.status.store ||
            this.status.cashier ||
            this.status.sales ||
            this.status.payment) {
        
      } else {
        if (this.$route.query.filter !== undefined) {
          this.$router.replace({ path: `${this.$route.path}` })
        }
      }
    },

    async fetchSummaryData (store_id) {
      let start_date = this.$refs["base-table"].filterModel.input_complete_datetime.value.start ? this.$refs["base-table"].filterModel.input_complete_datetime.value.start : ''
      let end_date = this.$refs["base-table"].filterModel.input_complete_datetime.value.end ? this.$refs["base-table"].filterModel.input_complete_datetime.value.end: '' 

      const response  = await get(`franchise/report/penjualan_refund/summary`,{
        params: {
          store_id: store_id,
          start_date,
          end_date,
          payment_method: this.$refs["base-table"].filterModel.payment_method.value ? this.$refs["base-table"].filterModel.payment_method.value : '',
          type_title: this.$refs["base-table"].filterModel.type_title.value ? this.$refs["base-table"].filterModel.type_title.value : ''
        }
      })

      let res = response.data

      if (res.status == 200) {
        this.total = res.results.data
      }
    },

    responseDataStart() {
      this.loading = true;
      this.process.run = true;
      this.displayBaseTable = false;
      this.emptyData = false;
      let item = [];

      //check value filterheader
      if (
        Object.keys(this.$refs["base-table"].filterModel.input_complete_datetime
          .value).length > 0
      ) {
        //get each item
        let start = this.$refs["base-table"].filterModel.input_complete_datetime.value.start
        let end = this.$refs["base-table"].filterModel.input_complete_datetime.value.end
       
        //assign params to item
        this.$refs["base-table"].params["filter[0][type]"] = "date";
        this.$refs["base-table"].params["filter[0][field]"] = "input_complete_datetime";
        this.$refs["base-table"].params["filter[0][comparison]"] = "bet";
        this.$refs["base-table"].params["filter[0][value]"] = start+"::"+end;
        //show chips
        this.status.date = true;
        this.filterdate = this.$options.filters.dateFull(start) + " - " + this.$options.filters.dateFull(end);
      }

      if (
        this.$refs["base-table"].filterModel.payment_method.value
      ) {

        // console.log(this.$refs["base-table"].filterModel.payment_method);
        // this.$refs["base-table"].params.payment_method = this.$refs["base-table"].filterModel.payment_method.value;
        this.$refs["base-table"].params["filter[1][type]"] = "string";
        this.$refs["base-table"].params["filter[1][field]"] = "payment_method";
        this.$refs["base-table"].params["filter[1][comparison]"] = "==";
        this.$refs["base-table"].params["filter[1][value]"] = this.$refs["base-table"].filterModel.payment_method.value;
        this.status.payment = true;
        this.filternamepayment = this.$refs[
          "base-table"
        ].filterModel.payment_method.value;
        // this.status.sales = true;
        this.filternamepayment = this.$refs[
          "base-table"
        ].filterModel.payment_method.value
          .split("_")
          .join(" ");
      }

      if (this.$refs["base-table"].filterModel.type_title.value) {
        this.$refs["base-table"].params["filter[2][type]"] = "string";
        this.$refs["base-table"].params["filter[2][field]"] = "type_title";
        this.$refs["base-table"].params["filter[2][comparison]"] = "==";
        this.$refs["base-table"].params["filter[2][value]"] =  this.$refs[
          "base-table"
        ].filterModel.type_title.value;
        this.status.sales = true;
        this.filtersalestype = this.$refs["base-table"].filterModel.type_title.value;

        this.filternamesales = this.$refs["base-table"].filterModel.type_title.value;
      }
    },

    responseDataEnd(res) {
      // console.log(res);
      this.loading = false;
      this.process.run = false;
      this.fetchSummaryData(this.selected_store_id)
      // this.total = res.data.summary;
      if (res.data.length <= 0) {
        this.emptyData = true;
        this.displayBaseTable = false;
      } else {
        this.emptyData = false;
        this.displayBaseTable = true;
      }
      this.contentEmpty = "Data tidak ditemukan";
    },

    responseExport() {
      //send params from base table to base export
      this.$refs["base-export-excel"].params = this.$refs["base-table"].params;
      this.$refs["base-export-pdf"].params = this.$refs["base-table"].params;

      // //KALAU MAU PAKE SEARCH
      // this.$refs["base-export"].params.search = this.$refs[
      //   "base-table"
      // ].searchVal;
    },

    /**
     * END EVENTS
     */
    disabledAfterThisDay(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },
    setFilter(value) {
      // console.log(value);
      this.filterdate = value;
    },
    async Export() {
      this.process.formExport = true;
      this.process.formExport = false;
    },

    fetchSales() {
      get(`franchise/store/sale_type`, {
        params: {
          store_id: this.selected_store_id,
          pagination_bool: false,
        },
      })
        .then((response) => {
          let res = response.data;
          if (res.status === 200) {
            this.sales = res.results.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            let errorData = error.response.data;
            let errorCode = error.response.status;
            let errorHeader = error.response.headers;

            if (errorCode === 401) {
              this.$store.state.unauthorization = true;
              this.process.run = false;
              this.process.form = false;
            }
          } else {
            // this.$store.state.errorNetwork = true;
            this.process.run = false;
            this.process.form = false;
          }
        });
    },
    fetchPaymentMethod() {
      get(`franchise/store/payment_method`, {
        params: {
          store_id: this.selected_store_id,
          pagination_bool: false,
        },
      })
        .then((response) => {
          let res = response.data;
          if (res.status === 200) {
            this.paymentMethod = res.results.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            let errorData = error.response.data;
            let errorCode = error.response.status;
            let errorHeader = error.response.headers;

            if (errorCode === 401) {
              this.$store.state.unauthorization = true;
              this.process.run = false;
              this.process.form = false;
            }
          } else {
            // this.$store.state.errorNetwork = true;
            this.process.run = false;
            this.process.form = false;
          }
        });
    },
    getDataModel(res) {
      // console.log(res);
      this.sales_id = res
    },
    async toDetail(item) {
      this.dialog.detailSalesResume = true;
      this.transaction_number = item.sales_order_transaction_number;
      // this.cardDetail = item.sales_resume_detail;
      // this.cardDetailSummary = item;
      await get(`franchise/report/penjualan_refund/detail`, {
        params : {
          store_id: this.selected_store_id,
          id: item.id 
        }
      }).then(response => {
        let res = response.data;
        if (res.status == 200) {
          this.cardDetail = res.results.data.product_list;
          this.cardDetailInfo = res.results.data.transaction_info_object;
          this.cardDetailSummary = res.results.data.summary_object;
        } else {
          this.cardDetail = [];
          this.cardDetailSummary = [];
        }
      })

    },
    
    openFilter() {
      this.filterdate = this.filterSave.date;
      this.filterstore = this.filterSave.store;
      this.filtercashier = this.filterSave.cashier;
      this.filtersalestype = this.filterSave.sales;
      this.filterpaymenttype = this.filterSave.payment;
      this.dialog.filtered = true;
    },
  },
};
</script>

<style scoped>
.background-bank-cols {
  background-color: #cfd8dc;
  border: 3px solid #cfd8dc;
  border-radius: 10px;
}
</style>
